import "./index.css";
import * as yup from "yup";
import { ErrorMessage, Formik, Form, Field } from "formik";
import Axios from "axios";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import logo_pilulas from "../../Assets/imgs/logo_pilulas.png";
import React, { Component }  from 'react';

import "react-datepicker/dist/react-datepicker.css";

function InfosPessoais({aoEnviar}) {
  // const [profissoes, setProfissoes] = useState({
  //   profissoes: [
  //     "Profissão não definida",
  //     "Administrador de Banco de Dados",
  //     "A\u00e7ougueiro",
  //     "Acompanhante de Idosos",
  //     "Acupunturista",
  //     "Administrador de Empresas",
  //     "Administrador de Redes",
  //     "Administrador de Redes Junior",
  //     "Administrador de Redes Pleno",
  //     "Administrador de Redes S\u00eanior",
  //     "Administrador de Sistemas",
  //     "Administrador Hospitalar",
  //     "Administrador P\u00fablico",
  //     "Advogado",
  //     "Advogado Civil",
  //     "Aeronauta",
  //     "Afiador de Ferramentas",
  //     "Agente Comunit\u00e1rio de Sa\u00fade",
  //     "Agente de Defesa Sanit\u00e1ria",
  //     "Agente de Viagens",
  //     "Agente Funer\u00e1rio",
  //     "Agente Penitenci\u00e1rio",
  //     "Agricultor",
  //     "Agr\u00f4nomo",
  //     "Ajudante de Motorista",
  //     "Ajudante de Transportes",
  //     "Alfaiate",
  //     "Almirante",
  //     "Almoxarife",
  //     "Ambientalista",
  //     "Ambulante",
  //     "Analista Administrativo",
  //     "Analista Administrativo de Vendas",
  //     "Analista Administrativo Financeiro",
  //     "Analista Ambiental",
  //     "Analista Comercial Interno",
  //     "Analista Comercial Externo",
  //     "Analista de Atendimento a Clientes",
  //     "Analista de Atendimento Comercial",
  //     "Analista de Banco",
  //     "Analista de Banco de Dados",
  //     "Analista de Cargos e Sal\u00e1rios",
  //     "Analista de Cobran\u00e7a",
  //     "Analista de Com\u00e9rcio Exterior",
  //     "Analista de Compras",
  //     "Analista de Comunica\u00e7\u00e3o",
  //     "Analista de Contabilidade",
  //     "Analista de Contas a Pagar e Receber",
  //     "Analista de Controladoria",
  //     "Analista de Controladoria Junior",
  //     "Analista de Controladoria Pleno",
  //     "Analista de Controladoria S\u00eanior",
  //     "Analista de Controle de Qualidade",
  //     "Analista de Cr\u00e9dito e Cobran\u00e7a",
  //     "Analista de Custos",
  //     "Analista de Departamento Pessoal",
  //     "Analista de Desenvolvimento de Produto",
  //     "Analista de Desenvolvimento de Sistemas",
  //     "Analista de Desenvolvimento Web",
  //     "Analista de Distribui\u00e7\u00e3o",
  //     "Analista de Ecommerce",
  //     "Analista de Estoque",
  //     "Analista de Engenharia El\u00e9trica",
  //     "Analista Financeiro",
  //     "Analista de Fiscal",
  //     "Analista Qu\u00edmico",
  //     "Anestesiologista",
  //     "Angiologista",
  //     "Antrop\u00f3logo",
  //     "Apicultor",
  //     "Apresentador",
  //     "Argumentista",
  //     "Armador",
  //     "Armeiro",
  //     "Arquiteto",
  //     "Arquiteto de Interiores",
  //     "Arquiteto Paisagista",
  //     "Arquiteto Urbanista",
  //     "Arquiteto Projetista",
  //     "Arquiteto Vendedor",
  //     "Arquivista",
  //     "Arrumadeira",
  //     "Artista de Espet\u00e1culo de Divers\u00f5es",
  //     "Artista Pl\u00e1stico",
  //     "Ascensorista",
  //     "Assessor de Imprensa",
  //     "Analista de Fundi\u00e7\u00e3o",
  //     "Analista de Inform\u00e1tica",
  //     "Analista de Investimentos",
  //     "Analista de Log\u00edstica",
  //     "Analista de Loja",
  //     "Analista de Manuten\u00e7\u00e3o",
  //     "Analista de Manuten\u00e7\u00e3o El\u00e9trica",
  //     "Analista de Marketing",
  //     "Analista de PCP",
  //     "Analista de Produ\u00e7\u00e3o",
  //     "Analista de Programa\u00e7\u00e3o",
  //     "Analista de Publicidade e Propaganda",
  //     "Analista de Qualidade",
  //     "Analista de Recrutamento e Sele\u00e7\u00e3o",
  //     "Analista de Recursos Humanos",
  //     "Analista de Redes",
  //     "Analista de Rela\u00e7\u00f5es P\u00fablicas",
  //     "Analista de Representa\u00e7\u00e3o Comercial",
  //     "Analista de SAC",
  //     "Analista de Seguran\u00e7a Patrimonial",
  //     "Analista de Seguros",
  //     "Analista de Sistemas",
  //     "Analista de Suporte T\u00e9cnico",
  //     "Analista de Suprimentos",
  //     "Analista de Tecnologia da Informa\u00e7\u00e3o (TI)",
  //     "Analista de Telecomunica\u00e7\u00f5es",
  //     "Analista de Telemarketing Ativo",
  //     "Analista de Telemarketing Receptivo",
  //     "Analista de Tesouraria",
  //     "Analista de Transportes",
  //     "Analista de Treinamento",
  //     "Analista de Treinamento S\u00eanior",
  //     "Analista Econ\u00f4mico",
  //     "Assistente Comercial Interno",
  //     "Assistente de Almoxarifado",
  //     "Assistente de Arquitetura",
  //     "Assistente Administrativo",
  //     "Assistente de Atendimento a Clientes",
  //     "Assistente de Atendimento Comercial",
  //     "Assistente de Cobran\u00e7a",
  //     "Assistente de Com\u00e9rcio Exterior",
  //     "Assistente de Compras",
  //     "Assistente de Comunica\u00e7\u00e3o",
  //     "Assistente de Contabilidade",
  //     "Assistente de Contas a Pagar e Receber",
  //     "Assistente de Controladoria",
  //     "Assistente de Controle de Qualidade",
  //     "Assistente de Cozinha",
  //     "Assistente de Cr\u00e9dito e Cobran\u00e7a",
  //     "Assistente de Custos",
  //     "Assistente de Departamento Pessoal",
  //     "Assistente de Desenvolvimento de Produto",
  //     "Assistente de Desenvolvimento de Sistemas",
  //     "Assistente de Distribui\u00e7\u00e3o",
  //     "Assistente de Ecommerce",
  //     "Assistente de Enfermagem",
  //     "Assistente de Engenharia El\u00e9trica",
  //     "Assistente de Estoque",
  //     "Assistente Financeiro",
  //     "Assistente de Inform\u00e1tica",
  //     "Assistente de Jornalismo",
  //     "Assistente de Limpeza",
  //     "Assistente de Log\u00edstica",
  //     "Assistente de Loja",
  //     "Assistente de Manuten\u00e7\u00e3o",
  //     "Assistente de Manuten\u00e7\u00e3o El\u00e9trica",
  //     "Assistente de Marketing",
  //     "Assistente de PCP",
  //     "Assistente de Produ\u00e7\u00e3o",
  //     "Assistente de Publicidade e Propaganda",
  //     "Assistente de Qualidade",
  //     "Assistente de Recrutamento e Sele\u00e7\u00e3o",
  //     "Assistente de Recursos Humanos",
  //     "Assistente de Representa\u00e7\u00e3o Comercial",
  //     "Assistente de SAC",
  //     "Assistente de Seguros",
  //     "Assistente de Sistemas",
  //     "Assistente de Suporte T\u00e9cnico",
  //     "Assistente de Suprimentos",
  //     "Assistente de Tecnologia da Informa\u00e7\u00e3o (TI)",
  //     "Assistente de Telecomunica\u00e7\u00f5es",
  //     "Assistente de Telemarketing Ativo",
  //     "Assistente de Telemarketing Receptivo",
  //     "Assistente de Tesouraria",
  //     "Assistente de Transportes",
  //     "Assistente de Treinamento",
  //     "Assistente de Vendas Externas",
  //     "Assistente Editorial",
  //     "Assistente Fiscal",
  //     "Assistente Social",
  //     "Astr\u00f4nomo",
  //     "Atendente",
  //     "Atendente de Ag\u00eancia",
  //     "Atendente Distribuidor",
  //     "Auditor Ambiental",
  //     "Auditor de Qualidade",
  //     "Auditor Externo",
  //     "Auditor Interno",
  //     "Auxiliar de Almoxarifado",
  //     "Auxiliar de Almoxarife",
  //     "Auxiliar de Arquivo",
  //     "Auxiliar de Banco de Dados",
  //     "Auxiliar de Biblioteca",
  //     "Auxiliar de Com\u00e9rcio Exterior",
  //     "Auxiliar de Contas a Pagar e Receber",
  //     "Auxiliar de Controle de Qualidade",
  //     "Auxiliar de Cozinha",
  //     "Auxiliar de Cr\u00e9dito e Cobran\u00e7a",
  //     "Auxiliar de Custos",
  //     "Auxiliar de Distribui\u00e7\u00e3o",
  //     "Auxiliar de Engenharia de Seguran\u00e7a do Trabalho",
  //     "Auviliar de Escrit\u00f3rio",
  //     "Auxiliar de Fundi\u00e7\u00e3o",
  //     "Auxiliar de Instala\u00e7\u00e3o",
  //     "Auxiliar de Limpeza",
  //     "Auxiliar de Manuten\u00e7\u00e3o Hidr\u00e1ulica",
  //     "Auxiliar de Padeiro",
  //     "Auxiliar de PCP",
  //     "Auxiliar de Programa\u00e7\u00e3o",
  //     "Auxiliar de SAC",
  //     "Auxiliar de Seguran\u00e7a",
  //     "Auxiliar de Serralheiro",
  //     "Auxiliar de Suprimentos",
  //     "Auxiliar de Topografia",
  //     "Auxiliar de Transportes",
  //     "Auxiliar de Usinagem",
  //     "Auxiliar de Veterin\u00e1ria",
  //     "Auxiliar de Web Design",
  //     "Auxiliar Econ\u00f4mico",
  //     "Auxiliar Fiscal",
  //     "Auxiliar Jur\u00eddico",
  //     "Bartender",
  //     "Bab\u00e1",
  //     "Balconista",
  //     "Banc\u00e1rio",
  //     "Barman",
  //     "Biblioteconista",
  //     "Bi\u00f3logo",
  //     "Biom\u00e9dico",
  //     "Bioqu\u00edmico",
  //     "Biotecn\u00f3logo",
  //     "Bombeiro",
  //     "Borracheiro",
  //     "Cabeleireiro",
  //     "Cadista",
  //     "Cardiologista",
  //     "Cartazista",
  //     "Carteiro",
  //     "Cen\u00f3grafo",
  //     "Ceramista",
  //     "Cineasta",
  //     "Cinegrafista",
  //     "Cirurgi\u00e3o Pl\u00e1stico",
  //     "Cl\u00ednico Geral",
  //     "Cobrador de Coletivo",
  //     "Comiss\u00e1rio de Bordo",
  //     "Confeiteiro",
  //     "Conferente",
  //     "Conferente de Loja",
  //     "Consultor Cont\u00e1bil",
  //     "Consultor de Marketing",
  //     "Consultor de Moda",
  //     "Consultor de Qualidade",
  //     "Consultor de Vendas Externas",
  //     "Consultor de Viagens",
  //     "Contador",
  //     "Controlador de Tr\u00e1fego",
  //     "Coordenador Administrativo",
  //     "Coordenador Comercial Externo",
  //     "Coordenador Comercial Interno",
  //     "Coordenador de Almoxarifado",
  //     "Coordenador de Arquitetura",
  //     "Coordenador de Atendimento Comercial",
  //     "Coordenador de Atendimento a Clientes",
  //     "Coordenador de Banco",
  //     "Coordenador de Cargos e Sal\u00e1rios",
  //     "Coordenador de Cobran\u00e7a",
  //     "Coordenador de Com\u00e9rcio Exterior",
  //     "Coordenador de Compras",
  //     "Coordenador de Comunica\u00e7\u00e3o",
  //     "Coordenador de Contabilidade",
  //     "Coordenador de Controladoria",
  //     "Coordenador de Controle de Qualidade",
  //     "Coordenador de Cozinha",
  //     "Coordenador de Cr\u00e9dito e Cobran\u00e7a",
  //     "Coordenador de Custos",
  //     "Coordenador de Departamento Pessoal",
  //     "Coordenador de Desenvolvimento de Produto",
  //     "Coordenador de Desenvolvimento de Sistemas",
  //     "Coordenador de Diagrama\u00e7\u00e3o",
  //     "Coordenador de Ecommerce",
  //     "Coordenador de Enfermagem",
  //     "Coordenador de Engenharia e Seguran\u00e7a do Trabalho",
  //     "Coordenador de Engenharia El\u00e9trica",
  //     "Coordenador de Estoque",
  //     "Coordenador de Fundi\u00e7\u00e3o",
  //     "Coordenador de Inform\u00e1tica",
  //     "Coordenador de Jornalismo",
  //     "Coordenador de Limpeza",
  //     "Coordenador de Log\u00edstica",
  //     "Coordenador de Loja",
  //     "Coordenador de Manuten\u00e7\u00e3o",
  //     "Coordenador de Manuten\u00e7\u00e3o El\u00e9trica",
  //     "Coordenador de Marketing",
  //     "Coordenador de PCP",
  //     "Coordenador de Produ\u00e7\u00e3o",
  //     "Coordenador de Publicidade e Propaganda",
  //     "Coordenador de Qualidade",
  //     "Coordenador de Recrutamento e Sele\u00e7\u00e3o",
  //     "Coordenador de Recursos Humanos",
  //     "Coordenador de Representa\u00e7\u00e3o Comercial",
  //     "Coordenador de SAC",
  //     "Coordenador de Sal\u00e3o",
  //     "Coordenador de Seguran\u00e7a Patrimonial",
  //     "Coordenador de Seguros",
  //     "Coordenador de Sistemas",
  //     "Coordenador de Suporte T\u00e9cnico",
  //     "Coordenador de Suprimentos",
  //     "Coordenador de Tecnologia da Informa\u00e7\u00e3o",
  //     "Coordenador de Telecomunica\u00e7\u00f5es",
  //     "Coordenador de Telemarketing Ativo",
  //     "Coordenador de Telemarketing Receptivo",
  //     "Coordenador de Tesouraraia",
  //     "Coordenador de Transportes",
  //     "Coordenador de Treinamento",
  //     "Coordenador de Usinagem",
  //     "Coordenador Econ\u00f4mico",
  //     "Coordenador Financeiro",
  //     "Coordenador Fiscal",
  //     "Coordenador Qu\u00edmico",
  //     "Corretor de Seguros",
  //     "Cozinheiro",
  //     "Diretor Administrativo",
  //     "Dentista",
  //     "Desenhista",
  //     "Designer de Interiores",
  //     "Designer de Moda",
  //     "Diagramador",
  //     "Diretor Comercial Externo",
  //     "Diretor Comercial Interno",
  //     "Diretor de Cinema",
  //     "Diretor de Prdu\u00e7\u00e3o",
  //     "Diretor de Representa\u00e7\u00e3o Comercial",
  //     "Diretor de Recursos Humanos",
  //     "Diretor de Tecnologia da Informa\u00e7\u00e3o (TI)",
  //     "Diretor de Marketing",
  //     "Diretor Financeiro",
  //     "Diretor de Inform\u00e1tica",
  //     "Diretor de Jornalismo",
  //     "Diretor de Suporte T\u00e9cnico",
  //     "Diretor de Log\u00edstica",
  //     "Diretor de Departamento Pessoal",
  //     "Ecologista",
  //     "Economista",
  //     "Editor",
  //     "Educador",
  //     "Eletricista",
  //     "Eletricista de Manuten\u00e7\u00e3o",
  //     "Embalador",
  //     "Empreagdo (a) Dom\u00e9stico (a)",
  //     "Encanador",
  //     "Encarregado de Almoxarifado",
  //     "Encarregado de PCP",
  //     "Encarregado de Recursos Humanos",
  //     "Encarregado de Seguran\u00e7a",
  //     "Encarregado de Transporte",
  //     "Encarregado de Usinagem",
  //     "Endocrinologista",
  //     "Engenheiro T\u00eaxtil",
  //     "Enfermeiro",
  //     "Engenheiro Aeron\u00e1utico",
  //     "Engenheiro Agrimensor",
  //     "Engenheiro Agr\u00f4nomo",
  //     "Engenheiro Ambiental",
  //     "Engenheiro Civil",
  //     "Engenheiro Civil Junior",
  //     "Engenheiro Civil Pleno",
  //     "Engenheiro Civil S\u00eanior",
  //     "Engenheiro da Computa\u00e7\u00e3o",
  //     "Engenheiro de Alimentos",
  //     "Engenheiro de Engenharia e Seguran\u00e7a do Trabalho",
  //     "Engenheiro de Produ\u00e7\u00e3o",
  //     "Engenheiro de Suprimentos",
  //     "Engenheiro de Telecomunica\u00e7\u00f5es",
  //     "Engenheiro Eletricista",
  //     "Engenheiro Metal\u00fargico",
  //     "Engenheiro Naval",
  //     "Engenheiro Nuclear",
  //     "Estagi\u00e1rio Comercial Externo",
  //     "Estagi\u00e1rio Comercial Interno",
  //     "Estagi\u00e1rio Administrativo",
  //     "Estagi\u00e1rio de Arquitetura",
  //     "Estagi\u00e1rio de Atendimento Comercial",
  //     "Estagi\u00e1rio de Atendimento a Clientes",
  //     "Estagi\u00e1rio de Banco",
  //     "Estagi\u00e1rio de Banco de Dados",
  //     "Estagi\u00e1rio de Biomedicina",
  //     "Estagi\u00e1rio de Cobran\u00e7a",
  //     "Estagi\u00e1rio de Com\u00e9rcio Exterior",
  //     "Estagi\u00e1rio de Compras",
  //     "Estagi\u00e1rio de Comunica\u00e7\u00e3o",
  //     "Estagi\u00e1rio de Contabilidade",
  //     "Estagi\u00e1rio de Controladoria",
  //     "Estagi\u00e1rio de Controle de Qualidade",
  //     "Estagi\u00e1rio de Cozinha",
  //     "Estagi\u00e1rio de Custos",
  //     "Estagi\u00e1rio de Departamento Pessoal",
  //     "Estagi\u00e1rio de Desenho Industrial",
  //     "Estagi\u00e1rio de Desenvolvimento de Produto",
  //     "Estagi\u00e1rio de Desenvolvimento de Sistemas",
  //     "Estagi\u00e1rio de Diagrama\u00e7\u00e3o",
  //     "Estagi\u00e1rio de Distribui\u00e7\u00e3o",
  //     "Estagi\u00e1rio de Ecommerce",
  //     "Estagi\u00e1rio de Economia",
  //     "Estagi\u00e1rio de Edifica\u00e7\u00f5es",
  //     "Estagi\u00e1rio de Educa\u00e7\u00e3o F\u00edsica",
  //     "Estagi\u00e1rio de Enfermagem",
  //     "Estagi\u00e1rio de Engenharia de Alimentos",
  //     "Estagi\u00e1rio de Engenharia de Telecomunica\u00e7\u00f5es",
  //     "Estagi\u00e1rio de Engenharia e Seguran\u00e7a do Trabalho",
  //     "Estagi\u00e1rio de Engenharia El\u00e9trica",
  //     "Estagi\u00e1rio de Estoque",
  //     "Estagi\u00e1rio de Gastronomia",
  //     "Estagi\u00e1rio de Gest\u00e3o Abiental",
  //     "Estagi\u00e1rio de Inform\u00e1tica",
  //     "Estagi\u00e1rio de Jornalismo",
  //     "Estagi\u00e1rio de Letras",
  //     "Estagi\u00e1rio de Log\u00edstica",
  //     "Estagi\u00e1rio de Manuten\u00e7\u00e3o",
  //     "Estagi\u00e1rio de Manuten\u00e7\u00e3o El\u00e9trica",
  //     "Estagi\u00e1rio de Marketing",
  //     "Estagi\u00e1rio de PCP",
  //     "Estagi\u00e1rio de Produ\u00e7\u00e3o",
  //     "Estagi\u00e1rio de Publicidade e Propaganda",
  //     "Estagi\u00e1rio de Qualidade",
  //     "Estagi\u00e1rio de Recrutamento e Sele\u00e7\u00e3o",
  //     "Estagi\u00e1rio de Recursos Humanos",
  //     "Estagi\u00e1rio de Representa\u00e7\u00e3o Comercial",
  //     "Estagi\u00e1rio de SAC",
  //     "Estagi\u00e1rio de Sistemas",
  //     "Estagi\u00e1rio de Suporte T\u00e9cnico",
  //     "Estagi\u00e1rio de Suprimentos",
  //     "Estagi\u00e1rio de Tecnologia da Informa\u00e7\u00e3o (TI)",
  //     "Estagi\u00e1rio de Telecomunica\u00e7\u00e3oes",
  //     "Estagi\u00e1rio de Telemarketing Ativo",
  //     "Estagi\u00e1rio de Telemarketing Receptivo",
  //     "Estagi\u00e1rio de Tesouraria",
  //     "Estagi\u00e1rio de Veterin\u00e1ria",
  //     "Estagi\u00e1rio de Web Design",
  //     "Estagi\u00e1rio Financeiro",
  //     "Estagi\u00e1rio Fiscal",
  //     "Estagi\u00e1rio Qu\u00edmico",
  //     "Esteticista",
  //     "Estilista",
  //     "Estoquista",
  //     "Estoquista de Loja",
  //     "Fundidor",
  //     "Farmac\u00eautico",
  //     "Feirante",
  //     "Fil\u00f3sofo",
  //     "F\u00edsico",
  //     "Fisioterapeuta",
  //     "Fonoaudi\u00f3logo",
  //     "Fot\u00f3grafo",
  //     "Fresador",
  //     "Fresador CNC",
  //     "Fresador Ferramenteiro",
  //     "Gar\u00e7om",
  //     "Gari",
  //     "Gastr\u00f4nomo",
  //     "Geof\u00edsico",
  //     "Geogr\u00e1fo",
  //     "Ge\u00f3logo",
  //     "Gerente Administrativo",
  //     "Gerente Comercial Externo",
  //     "Gerente Comercial Interno",
  //     "Gerente Comercial Interno",
  //     "Gerente de Atendimento a Clientes",
  //     "Gerente de Atendimento Comercial",
  //     "Gerente de Banco",
  //     "Gerente de Cobran\u00e7a",
  //     "Gerente de Com\u00e9rcio Exterior",
  //     "Gerente de Compras",
  //     "Gerente de Comunica\u00e7\u00e3o",
  //     "Gerente de Contabilidade",
  //     "Gerente de Contas",
  //     "Gerente de Controladoria",
  //     "Gerente de Controle de Qualidade",
  //     "Gerente de Cozinha",
  //     "Gerente de Departamento Pessoal",
  //     "Gerente de Desenvolvimento de Produto",
  //     "Gerente de Desenvolvimento de Sistemas",
  //     "Gerente de Distribui\u00e7\u00e3o",
  //     "Gerente de Ecommerce",
  //     "Gerente de Enfermagem",
  //     "Gerente de Engenharia El\u00e9trica",
  //     "Gerente de Estoque",
  //     "Gerente de Hotelaria",
  //     "Gerente de Inform\u00e1tica",
  //     "Gerente de Jornalismo",
  //     "Gerente de Log\u00edstica",
  //     "Gerente de Loja",
  //     "Gerente de Manuten\u00e7\u00e3o",
  //     "Gerente de Manuten\u00e7\u00e3o El\u00e9trica",
  //     "Gerente de Marketing",
  //     "Gerente de PCP",
  //     "Gerente de Produ\u00e7\u00e3o",
  //     "Gerente de Publicidade e Propaganda",
  //     "Gerente de Qualidade",
  //     "Gerente de Recrutamento e Sele\u00e7\u00e3o",
  //     "Gerente de Recursos Humanos",
  //     "Gerente de Representa\u00e7\u00e3o Comercial",
  //     "Gerente de SAC",
  //     "Gerente de Sal\u00e3o",
  //     "Gerente de Seguran\u00e7a Patrimonial",
  //     "Gerente de Seguros",
  //     "Gerente de Sistemas",
  //     "Gerente de Suporte T\u00e9cnico",
  //     "Gerente de Suprimentos",
  //     "Gerente de Tecnologia da Informa\u00e7\u00e3o (TI)",
  //     "Gerente de Telecomunica\u00e7\u00f5es",
  //     "Gerente de Telemarketing Ativo",
  //     "Gerente de Telemarketing Receptivo",
  //     "Gerente de Tesouraria",
  //     "Gerente de Transportes",
  //     "Gerente Financeiro",
  //     "Gerente Fiscal",
  //     "Gerente Qu\u00edmico",
  //     "Geriatra",
  //     "Gestor Ambiental",
  //     "Ginecologista",
  //     "Governanta",
  //     "Homeopata",
  //     "Historiador",
  //     "Inspetor de Controle de Qualidade",
  //     "Instalador de Acess\u00f3rios Automotivos",
  //     "Instalador de Alarmes",
  //     "Instalador de Cabeamento Estruturado",
  //     "Instalador de Telecomunica\u00e7\u00f5es",
  //     "Instrutor de Treinamento",
  //     "Jornalista",
  //     "Jardineiro",
  //     "L\u00edder de Produ\u00e7\u00e3o",
  //     "L\u00edder de Usinagem",
  //     "Locutor",
  //     "Maestro",
  //     "Mandrilhador",
  //     "Manicure/Pedicure",
  //     "Manobrista",
  //     "Maquiador",
  //     "Marceneiro",
  //     "Marinheiro",
  //     "Matem\u00e1tico",
  //     "Mec\u00e2nico",
  //     "Mec\u00e2nico de Autom\u00f3veis",
  //     "Mec\u00e2nico de Manuten\u00e7\u00e3o Hidr\u00e1ulica",
  //     "M\u00e9dico",
  //     "MestredeObras",
  //     "Modelo",
  //     "Monitor de Atendimento a Clientes",
  //     "Monitor de Telemarketing Ativo",
  //     "Monitor de Telemarketing Receptivo",
  //     "Motoboy",
  //     "Motorista",
  //     "Neurologista",
  //     "Nutricionista",
  //     "Obstetra",
  //     "Oftalmologista",
  //     "Operador de Atendimento a Clientes",
  //     "Operador de Caixa",
  //     "Operador de Fundi\u00e7\u00e3o",
  //     "Operador de Guilhotina",
  //     "Operador de Log\u00edstica",
  //     "Operador de Produ\u00e7\u00e3o",
  //     "Operador de SAC",
  //     "Operador de Telemarketing Ativo",
  //     "Operador de Telemarketing Receptivo",
  //     "Operador de Torno",
  //     "Operador de Usinagem",
  //     "Ortopedista",
  //     "Otorrinolaringologista",
  //     "Psic\u00f3logo",
  //     "Padeiro",
  //     "Pescador",
  //     "Paisagista",
  //     "Pediatra",
  //     "Pedreiro",
  //     "Pintor",
  //     "Pod\u00f3logo",
  //     "Polidor",
  //     "Produtor Editorial",
  //     "Professor de Educa\u00e7\u00e3o",
  //     "Professor de Letras",
  //     "Programador de Centro de Usinagem",
  //     "Programador de PCP",
  //     "Promotor Banc\u00e1rio",
  //     "Qu\u00edmico",
  //     "Recepcionista",
  //     "Radialista",
  //     "Radiologista",
  //     "Redator",
  //     "Rep\u00f3rter",
  //     "Representante Comercial",
  //     "Supervisor Administrativo",
  //     "Secret\u00e1ria",
  //     "Serralheiro",
  //     "Supervisor Comercial Externo",
  //     "Supervisor Comercial Interno",
  //     "Supervisor de Almoxarifado",
  //     "Supervisor de Atendimento a Clientes",
  //     "Supervisor de Atendimento Comercial",
  //     "Supervisor de Banco",
  //     "Supervisor de Cargos e Sal\u00e1rios",
  //     "Supervisor de Cobran\u00e7a",
  //     "Supervisor de Com\u00e9rcio Exterior",
  //     "Supervisor de Compras",
  //     "Supervisor de Comunica\u00e7\u00e3o",
  //     "Supervisor de Contabilidade",
  //     "Supervisor de Controladoria",
  //     "Supervisor de Controle de Qualidade",
  //     "Supervisor de Cozinha",
  //     "Supervisor de Cr\u00e9dito e Cobran\u00e7a",
  //     "Supervisor de Custos",
  //     "Supervisor de Departamento Pessoal",
  //     "Supervisor de Desenvolvimento de Produto",
  //     "Supervisor de Desenvolvimento de Sistemas",
  //     "Supervisor de Distribui\u00e7\u00e3o",
  //     "Supervisor de Ecommerce",
  //     "Supervisor de Enfermagem",
  //     "Supervisor de Engenharia e Seguran\u00e7a do Trabalho",
  //     "Supervisor de Engenharia El\u00e9trica",
  //     "Supervisor de Estoque",
  //     "Supervisor de Fundi\u00e7\u00e3o",
  //     "Supervisor de Inform\u00e1tica",
  //     "Supervisor de Limpeza",
  //     "Supervisor de Log\u00edstica",
  //     "Supervisor de loja",
  //     "Supervisor de Manuten\u00e7\u00e3o",
  //     "Supervisor de Marketing",
  //     "Supervisor de Manuten\u00e7\u00e3o El\u00e9trica",
  //     "Supervisor de PCP",
  //     "Supervisor de Produ\u00e7\u00e3o",
  //     "Supervisor de Publicidade e Propaganda",
  //     "Supervisor de Qualidade",
  //     "Supervisor de Recrutamento e Sele\u00e7\u00e3o",
  //     "Supervisor de Recursos Humanos",
  //     "Supervisor de Representa\u00e7\u00e3o Comercial",
  //     "Supervisor de SAC",
  //     "Supervisor de Sal\u00e3o",
  //     "Supervisor de Seguran\u00e7a",
  //     "Supervisor de Seguran\u00e7a Patrimonial",
  //     "Supervisor de Seguros",
  //     "Supervisor de Sistemas",
  //     "Supervisor de Suporte T\u00e9cnico",
  //     "Supervisor de Suprimentos",
  //     "Supervisor de Tecnologia da Informa\u00e7\u00e3o (TI)",
  //     "Supervisor de Telecomunica\u00e7\u00f5es",
  //     "Supervisor de Telemarketing Ativo",
  //     "Supervisor de Telemarketing Receptivo",
  //     "Supervisor de Tesouraria",
  //     "Supervisor de Transportes",
  //     "Supervisor de Usinagem",
  //     "Supervisor Econ\u00f4mico",
  //     "Supervisor Financeiro",
  //     "Supervisor Fiscal",
  //     "T\u00e9cnico Ambiental",
  //     "T\u00e9cnico de Controle de Qualidade",
  //     "T\u00e9cnico de Edifica\u00e7\u00f5es",
  //     "T\u00e9cnico de Suporte",
  //     "T\u00e9cnico em Manuten\u00e7\u00e3o El\u00e9trica",
  //     "T\u00e9cnico em An\u00e1lise Cl\u00ednica",
  //     "T\u00e9cnico em An\u00e1lise Qu\u00edmica",
  //     "T\u00e9cnico em Automa\u00e7\u00e3o",
  //     "T\u00e9cnico em Guia de Turismo",
  //     "T\u00e9cnico em Hardware",
  //     "T\u00e9cnico em Metal\u00fargica",
  //     "T\u00e9cnico em Redes",
  //     "T\u00e9cnico em Secretariado",
  //     "T\u00e9cnico em Secretariado Escolar",
  //     "T\u00e9cnico em Transa\u00e7\u00f5es Imobili\u00e1rias",
  //     "T\u00e9cnico em Zootecnia",
  //     "T\u00e9cnico Instalador",
  //     "T\u00e9cnico em Telecomunica\u00e7\u00f5es",
  //     "Tesoureiro",
  //     "Top\u00f3grafo",
  //     "Tradutor",
  //     "Traumatologista",
  //     "Turism\u00f3logo",
  //     "Urologista",
  //     "Vendedor Externo",
  //     "Vendedor de Loja",
  //     "Vendedor Interno",
  //     "Veterin\u00e1rio",
  //     "Vigilante",
  //     "Vigilante Condutor",
  //     "Vigilante de Escolta",
  //     "Vigilante de Monitoramento",
  //     "Vigilante de Seguran\u00e7a Patrimonial",
  //     "Vigilante de Seguran\u00e7a Social Privada",
  //     "Vigilante L\u00edder",
  //     "Vitrinista",
  //     "Web Design",
  //     "Web Design S\u00eanior",
  //     "Web Design Junior",
  //     "Zelador",
  //     "Zootecnista",
  //   ],
  // });

  const [startDate, setStartDate] = useState(new Date());

  const[nomeCompleto, setNomeCompleto] = useState();
  const[phone, setPhone] = useState();
  const[dataNasc, setDataNasc] = useState();
  const[profissao, setProfissao] = useState();
  const[empresa, setEmpresa] = useState();

  const handleSubmit = (values) => {
    // setNomeCompleto(values.nome)
    // setPhone(values.phone)
    // setDataNasc(values.data_nasc)
    // setProfissao(values.profissao)

    



    aoEnviar({ nomeCompleto:values.nome, phone:values.phone, dataNasc:values.data_nasc, empresa: values.empresa, profissao:values.profissao });
  };


  

  const validationsRegister = yup.object().shape({
    
    nome: yup
      .string()
      .required("Não deixe de nos dizer seu nome, ele é fundamental!"),
    phone: yup
      .string()
      .required("Seu telefone é o nosso contato mais próximo, preencha ele!"),
    data_nasc: yup.string().required("Nos diga quando você nasceu!"),
    
  });

  

  return (
    <div>
      <div className="body">
        <div className="card-cadastro">
      {/* <img src={logo_pilulas} alt="" className="logo_pilulas" /> */}
          <h1>Informações pessoais</h1>
          <p>Primeiro de tudo, vamos nos conhecer. Nos diga, quem é você? </p>
          

          <Formik
            initialValues={{}}
            onSubmit={handleSubmit}
            validationSchema={validationsRegister}
            render={({ isValid, setFieldValue }) => (
              <Form className="login-form">
               
            



                  <label form="nome">Nome completo</label>

                  <Field
                    name="nome"
                    type="text"
                    className="form-field"
                    placeholder="Nome completo"
                  />
                  <ErrorMessage
                    component="span"
                    name="nome"
                    className="form-error"
                  /><br></br>



                  <label form="phone">Telefone:</label>
                  <Field
                    name="phone"
                    type="tel"
                    className="form-field"
                    placeholder="Celular"
                    />
                  <ErrorMessage
                    component="span"
                    name="phone"
                    className="form-error"
                    /><br></br>
                    
                <label form="data_nasc">Data de nascimento:</label>
                    <Field name="data_nasc" type="date" className="form-field" />
                    <ErrorMessage
                      component="span"
                      name="data_nasc"
                      className="form-error"
                      /><br></br>

                    

                  {/* <label form="profissao">Profissão</label>
                  <Field as="select" name="profissao" className="form-field">
                    {profissoes.profissoes.map((value, key) => (
                      <option value={value} key={key}>
                        {value}
                      </option>
                    ))}
                  </Field> */}

                    <label form="empresa">Empresa</label>

                    <Field
                      name="empresa"
                      type="text"
                      className="form-field"
                      placeholder="Empresa que você trabalha"
                    />

                  <label form="profissao">Cargo</label>

                  <Field
                    name="profissao"
                    type="text"
                    className="form-field"
                    placeholder="Cargo que você ocupa"
                  />

                  


              
                <button className="button" type="submit" >
                  PRÓXIMO
                </button>
                <p class="termos">
                 Já nos conhecemos? 
                  <Link to={'/login'} className="link_termos">
                  &nbsp;Clique aqui para fazer login!&nbsp;
                    </Link>
                    
                  </p>
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default InfosPessoais;
